.nav-bar-main {
  z-index: 1000;
  box-shadow: 2px 3px 10px #888888;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
  padding-top: 50px;
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 50%;
  text-align: center;
  font-size: 17px;
  line-height: 1.42857;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.6);
  -moz-box-shadow: 2px 2px 2px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 2px 2px 2px  rgba(0,0,0,0.6);
  -o-box-shadow: 2px 2px 2px  rgba(0,0,0,0.6);
}

.btn-circle-right {
  position: absolute;
  right: 55px;
  top: 70px;
}

.div-container-border {
  border: 1px solid #CED4DA;
  box-shadow: 2px 3px 10px #888888;
  padding: 10px;
  width: 100%;
}

/* Sidebar */

.sidebar {
  position: fixed;
  top: -40vh;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
.sidebar > div > ul > li {
  display: block;
  margin: 0 auto;
  width: 160px;
  margin-left: -40px;
  list-style-type: none;
}
.sidebar > div > ul > li > a > svg {
  font-size: 20px;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

/* Cron generator */
div.cron_builder {
  width: 100% !important;
  border: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

div.cron_builder li {
  width: auto;
  margin-left: 0px;
  padding-right: 5px;
  padding-left: 5px;
  color: #086090;
}

div.cron_builder li:hover {
  background-color: #EBEBE4;
}

div.cron_builder li.active {
  border: 1px solid #DDDDDD;
  z-index: 200;
  border-bottom: 0;
  color: black;
}


div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.div-image-screenshow-small {
  width: 23% !important;
}

.div-image-screenshow-large {
  width: 95% !important;
}